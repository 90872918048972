import { useContext } from 'react';

import './ContactPopup.css';


import { ClosePopupIcon } from '../../assets/icons/icons';
import Popup from '../Popup/Popup';
import { ContactContext } from '../../assets/contexts/contactContext';
import { ConfigContext } from '../../assets/contexts/configContext';
import { DEFAULT_SOCIAL_VALUES_MAP, TELEGRAM_SOCIAL, VIBER_SOCIAL, WHATSAPP_SOCIAL } from '../../assets/utils/socials/socials_constants';
import { PHONE } from '../../assets/utils/constants';


function ContactPopup() {
  const { isContactPopupOpen: isOpen, setContactPopupOpen: setOpen } =
    useContext(ContactContext);

  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { SOCIAL_NETWORKS } = initialConstants

  console.log({ SOCIAL_NETWORKS })

  function closePopup() {
    setOpen(false);
    setTimeout(() => {
    }, 300);
  }

  const FILTERED_SOCIAL_NETWORKS = SOCIAL_NETWORKS && Array.isArray(SOCIAL_NETWORKS) && SOCIAL_NETWORKS.length > 0 ?
    SOCIAL_NETWORKS.filter((item) => {
      if ([TELEGRAM_SOCIAL.type, WHATSAPP_SOCIAL.type, VIBER_SOCIAL.type].indexOf(item.type) >= 0) return true
      else return false
    })
    : null

  return (
    <Popup className="contact-popup" popupName={'contact-popup'} onClose={closePopup} {...{ setOpen, isOpen }}>
      <button
        className="contact-popup__close-btn"
        type="button"
        onClick={closePopup}
      >
        <ClosePopupIcon
          mainClassName="contact-popup__close-icon"
          strokeClassName="contact-popup__close-icon-stroke"
        />
      </button>
      <div className="contact-popup__content">
        <p className="contact-popup__title">Связаться с нами</p>
        <div className='contact-popup__items'>
          <a className='contact-popup__item contact-popup__item_phone' href={`tel:${PHONE.value}`}>
            <h3>{PHONE.formated}</h3>
          </a>
          {FILTERED_SOCIAL_NETWORKS && Array.isArray(FILTERED_SOCIAL_NETWORKS) && FILTERED_SOCIAL_NETWORKS.length > 0 ?
            FILTERED_SOCIAL_NETWORKS.map((social, i) => {
              const SOCIAL = DEFAULT_SOCIAL_VALUES_MAP[social.type]
              return (
                <a className='contact-popup__item' href={social.url} target='_blank' rel='noreferrer' key={`contact-popup__item${social._id}${i}`}>
                  {SOCIAL.icon({
                    mainClassName: 'contact-popup__item-icon',
                    fillClassName: 'contact-popup__item-icon-fill',
                    bgFillClassName: 'contact-popup__item-icon-bg-fill',
                  })}
                  <h3>{SOCIAL.title}</h3>
                </a>
              )
            })
            :
            <p className='contact-popup__no-items'>Контактная информация пока не размещена</p>}
        </div>
      </div>
    </Popup>
  );
}

export default ContactPopup;
