import { AboutBannerLogo } from '../../../../assets/icons/custom';
import './AboutBanner.css';

function AboutBanner() {
    return (
        <div className='about-banner'>
            <AboutBannerLogo mainClassName={'about-banner__logo'} fillClassName={'about-banner__logo-fill'} />
            <div className='about-banner__content'>
                <h2 className='about-banner__title'>LEFF LINE</h2>
                <p className='about-banner__text'>Вокруг такое разнообразие подарков, а&nbsp;времени на&nbsp;поиск и&nbsp;оформление всегда не&nbsp;хватает.
                    <br /><br />
                    Радуйте близких заботой, передав все хлопоты профессионалам!
                    <br /><br />
                    С&nbsp;Leff Line подарок будет доставлять удовольствие не&nbsp;только адресату, но&nbsp;и&nbsp;Вам.</p>
            </div>
        </div>
    );
}

export default AboutBanner