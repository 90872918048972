import { useContext } from 'react';
import { ContactContext } from '../../../../assets/contexts/contactContext';
import { AboutBannerLogo } from '../../../../assets/icons/custom';
import './СustomBanner.css';

function СustomBanner() {
    const { setContactPopupOpen: setContactOpen } = useContext(ContactContext);

    function openContactPopup() {
      setContactOpen(true);
    }

    return (
        <div className='сustom-banner'>
            <div className='сustom-banner__logo-box'>
                <svg className='сustom-banner__logo-circle' width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="100" cy="100" r="95" stroke="#ECC781" stroke-width="10" />
                </svg>
                <AboutBannerLogo mainClassName={'сustom-banner__logo'} fillClassName={'сustom-banner__logo-fill'} />
            </div>
            <div className='сustom-banner__content'>
                <div className='сustom-banner__texts'>
                    <h3 className='сustom-banner__title'>LEFF LINE</h3>
                    <p className='сustom-banner__text'>Ваш персональный помощник <br />в&nbsp;мире подарков!</p>
                </div>
                <button className='сustom-banner__button' type='button' onClick={openContactPopup}>
                    Подобрать подарок
                </button>
            </div>
            <div className='сustom-banner__bg-elem сustom-banner__bg-elem_1'></div>
            <div className='сustom-banner__bg-elem сustom-banner__bg-elem_2'></div>
            <div className='сustom-banner__bg-elem сustom-banner__bg-elem_3'></div>
            <div className='сustom-banner__bg-elem сustom-banner__bg-elem_4'></div>
        </div>
    );
}

export default СustomBanner