import { BenefitIcon1, BenefitIcon2, BenefitIcon3, BenefitIcon4} from '../../../../assets/icons/custom';
import './Benefits.css';

const items = [
    {
        title: `Эксклюзивный <br />ассортимент`,
        text: `Наша коллекция подарков уникальна. Многие товары нашего магазина выпущены ограниченным тиражом или являются уникальным продуктом ручной работы мастера.`,
        icon: BenefitIcon1,
    },
    {
        title: `Фирменная <br />упаковка`,
        text: `Наша стильная подарочная упаковка придаст неповторимый шарм, чтобы адресат в&nbsp;полной мере ощутил позитивную энергию вашего подарка.`,
        icon: BenefitIcon2,
    },
    {
        title: `Персональный <br />сервис`,
        text: `Наши специалисты помогут вам определиться с&nbsp;подарком, подобрать оригинальную упаковку и&nbsp;оформить открытку с&nbsp;персональным текстом для адресата.`,
        icon: BenefitIcon3,
    },
    {
        title: `Оперативная <br />доставка`,
        text: `Наша собственная курьерская служба доставит Ваш подарок адресату по&nbsp;Москве в&nbsp;тот&nbsp;же день. Также мы&nbsp;можем оформить посылку для отправки заказа в&nbsp;любую точку мира.`,
        icon: BenefitIcon4,
    },
]

function Benefits() {
    return (
        <div className='benefits'>
            {items.map((item, i) => (
                <div className='benefits__item' key={`benefits__item_${i}`}>
                    <div className='benefits__item-texts'>
                        <h4 className='benefits__item-title' dangerouslySetInnerHTML={{
                            __html: item.title,
                        }}></h4>
                        <p className='benefits__item-text' dangerouslySetInnerHTML={{
                            __html: item.text,
                        }}></p>
                    </div>
                    {item.icon({ mainClassName: `benefits__item-icon benefits__item-icon_${i + 1}`, strokeClassName: `benefits__item-icon-stroke` })}
                </div>
            ))}

        </div>
    );
}

export default Benefits